// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-file-upload .fileContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
}
app-file-upload .fileContainer [type=file] {
  font-size: 50px;
  filter: alpha(opacity=0);
  min-width: 1000px;
  opacity: 0;
  position: absolute;
  right: -80px;
  top: -15px;
}
app-file-upload .hidden {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbGUtdXBsb2FkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsWUFBQTtFQUNBLGdCQUFBO0VBQ0Esa0JBQUE7QUFBSjtBQUdFO0VBQ0UsZUFBQTtFQUNBLHdCQUFBO0VBQ0EsaUJBQUE7RUFDQSxVQUFBO0VBQ0Esa0JBQUE7RUFDQSxZQUFBO0VBQ0EsVUFBQTtBQURKO0FBR0U7RUFDRSxhQUFBO0FBREoiLCJmaWxlIjoiZmlsZS11cGxvYWQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJhcHAtZmlsZS11cGxvYWQge1xuICAuZmlsZUNvbnRhaW5lciB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB9XG5cbiAgLmZpbGVDb250YWluZXIgW3R5cGU9XCJmaWxlXCJdIHtcbiAgICBmb250LXNpemU6IDUwcHg7XG4gICAgZmlsdGVyOiBhbHBoYShvcGFjaXR5PTApO1xuICAgIG1pbi13aWR0aDogMTAwMHB4O1xuICAgIG9wYWNpdHk6IDA7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiAtODBweDtcbiAgICB0b3A6IC0xNXB4O1xuICB9XG4gIC5oaWRkZW4ge1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/file-upload/file-upload.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,wBAAA;EACA,iBAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AADJ;AAGE;EACE,aAAA;AADJ;AACA,g4BAAg4B","sourcesContent":["app-file-upload {\n  .fileContainer {\n    height: 100%;\n    overflow: hidden;\n    position: relative;\n  }\n\n  .fileContainer [type=\"file\"] {\n    font-size: 50px;\n    filter: alpha(opacity=0);\n    min-width: 1000px;\n    opacity: 0;\n    position: absolute;\n    right: -80px;\n    top: -15px;\n  }\n  .hidden {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
