import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LoginService } from "src/app/main/authentication/login/login.service";

@Injectable({
  providedIn: "root",
})
export class AuthAccountGuard
  
{
  /**
   * Constructor
   */
  constructor(private loginService: LoginService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    // Check the authentication status
    return this.loginService.isAuthenticated().pipe(
      switchMap((authenticated) => {
        console.log(authenticated);
        // If the user is not authenticated...
        if (authenticated.isAuth == false || authenticated.emailVerified == undefined) {
          console.log("navigate --- login", authenticated);
          // Redirect to the sign-in page
          this._router.navigate(["/auth/login"]);

          // Prevent the access
          return of(false);
        } else if (authenticated.emailVerified == false) {
          this._router.navigate(["/auth/verify-email"]);
          // Prevent the access
          return of(false);
        } else if (authenticated.mobileVerified == false) {
          this._router.navigate(["/auth/verify-mobile"]);
          // Prevent the access
          return of(false);
        } else if (
          authenticated.profileVerified === 0 ||
          authenticated.profileVerified === 1
        ) {
          this._router.navigate(["/auth/id-check"]);
          // Prevent the access
          return of(false);
        }
        // Allow the access
        return of(true);
      })
    );
  }
}
