import { Component, OnInit } from "@angular/core";
import {
  MenuController,
  ModalController,
  NavController,
  Platform,
} from "@ionic/angular";

import { StatusBar, Style } from "@capacitor/status-bar";
import { Intercom } from "@supy-io/ngx-intercom";
import { SwUpdate } from "@angular/service-worker";

import { environment } from "../environments/environment";
import { PresentersService } from "./shared/services/presenters.service";
import { RefreshService } from "./shared/services/refresh.service";
import { takeUntil } from "rxjs/operators";
import { AuthAccountPage } from "./main/authentication/auth-account/auth-account.page";
import { LoginService } from "./main/authentication/login/login.service";
import { ProfileService } from "./main/profile/profile.service";
import { Subject } from "rxjs";
import { AppSetting } from "./setting/app-setting";
import { UtilService } from "./shared/services/util.service";
import { PreferencePage } from "./main/preference/preference.page";
import { TapTestService } from "./shared/services/tapTest.service";
import { register } from 'swiper/element/bundle';

register()

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUserProfile: any = {};
  viewAvatar = false;
  avatarUrl = "";
  unsubscribeAll = new Subject<any>();
  profileComplete = true;
  currentModal = null;
  accountRole = AppSetting.USER_ROLE_RESOURCE;
  theme = "";
  menuEnabled = false;
  version = "";
  isTestMode = false;
  isProduction = environment.production;
  isVenueManager = false;

  constructor(
    private platform: Platform,
    private intercom: Intercom,
    public swUpdate: SwUpdate,
    private presenters: PresentersService,
    private refreshService: RefreshService,
    private navController: NavController,
    private profileService: ProfileService,
    private loginService: LoginService,
    private menu: MenuController,
    public modalController: ModalController,
    private utils: UtilService,
    public tapTestService: TapTestService
  ) {
    this.isTestMode = localStorage.getItem("__env_ovr") === "test";

    if (!this.platform.is("capacitor")) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/ngsw-worker.js")
          .then(() => console.log("service worker installed"))
          .catch((err) => console.log(err));
      }
    }

    this.profileService.currentUserProfile
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((currProf) => {
        this.currentUserProfile = currProf;
        if (
          this.currentUserProfile &&
          this.currentUserProfile.imageUrl &&
          this.currentUserProfile.imageUrl !== ""
        ) {
          this.viewAvatar = true;
          this.avatarUrl = this.currentUserProfile.imageUrl;
        } else {
          this.viewAvatar = false;
        }

        this.handleCheckUserRole();
      });

    this.initializeApp();

    this.profileService.accountRole.subscribe((value) => {
      this.accountRole = value;
    });

    this.profileService.theme.subscribe((value) => {
      this.theme = value;
    });

    this.handleCheckAuth();
  }

  handleCheckUserRole() {
    const accountList = this.profileService.userAccounts.getValue();
    accountList.forEach((account) => {
      const managerItem = (account.members || []).find(
        (memberItem) =>
          memberItem?.email === this.currentUserProfile?.email &&
          memberItem?.role_name === "Manager"
      );
      if (managerItem) {
        this.isVenueManager = true;
      }
    });
  }

  ngOnInit() {
    this.utils.getVersionOfApp();
    this.version = this.utils.versionOfApp;
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((res) => {
        if(res.type === 'VERSION_READY') {
          this.presenters.presentAlert({
            header: "New version.",
            message: "New version available. Reloading App.",
            buttons: [
              {
                text: "Ok",
                handler: (data) => {
                  // tslint:disable-next-line: deprecation
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
    }
  }

  initializeApp() {
    this.refreshService.loginWithRefresh();
    this.platform.ready().then(async () => {
      if (this.platform.is("capacitor")) {
        try {
          await StatusBar.setStyle({style: Style.Default});
          if (this.platform.is("android")) {
            // setTimeout(() => {
              StatusBar.setOverlaysWebView({ overlay: false });
              await StatusBar.setBackgroundColor({
                color: "#000000"
              })
            // }, 100);
          }
          // if (this.platform.is("ios")) {
          //   StatusBar.setOverlaysWebView({ overlay: false });
          //   await StatusBar.setBackgroundColor({
          //     color: "#FAFAFA"
          //   })
          // }
        } catch(error) {
          console.log("error ---", error)
        }
      }
    });

    this.intercom.boot({
      app_id: environment.intercomAPIKey,
      // widget: {
      //   activator: "#intercom",
      // },
      // version: this.utils.versionOfApp,
      hide_default_launcher: true,
    });
  }

  isEmpty(item) {
    if (item && item.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  handleLogout() {
    this.loginService.signOut();
    this.menu.enable(true, "mainMenu");
    this.menu.close("mainMenu");
    this.menuEnabled = false;
    this.menu.enable(false, "mainMenu");
  }

  handleNavigate(value) {
    switch (value) {
      case "change-password":
        this.navController.navigateForward([`auth/${value}`]);
        break;
      case "help":
        this.intercom.show();
        break;
      default:
        this.navController.navigateForward([`/${value}`]);
        break;
    }

    this.menu.enable(true, "mainMenu");
    this.menu.close("mainMenu");
  }

  handleSwitchAccount() {
    this.menu.close("mainMenu");
    this.navController.navigateRoot(["/auth/user-role"]);
  }

  handleVenueManager() {
    this.menu.close("mainMenu");
    this.navController.navigateRoot(["/auth/user-role"])
  }

  handleChangeRole() {
    this.menu.close("mainMenu");
    this.openModal("account-role");
  }

  handlePreference() {
    this.menu.close("mainMenu");
    this.openModal("preference");
  }

  async openModal(method, opts = {}) {
    if (method === "account-role") {
      const modal = await this.modalController.create({
        component: AuthAccountPage,
        ...opts,
      });
      modal.present();
    }

    if (method === "preference") {
      const modal = await this.modalController.create({
        component: PreferencePage,
        ...opts,
      });
      modal.present();
    }
  }

  handleCheckAuth() {
    this.loginService.tokenData.subscribe((authenticated) => {
      if (
        authenticated?.email_verified &&
        authenticated?.mobile_verified &&
        authenticated?.profile_verified === 2
      ) {
        this.menuEnabled = true;
      } else {
        this.menuEnabled = false;
      }
    });
  }

  handleVenue() {
    this.menu.close("mainMenu");
    this.profileService.setUserRole(AppSetting.USER_ROLE_RESOURCE)
  }
}
