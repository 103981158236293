import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TapTestService {
  private taps: number[] = [];
  private tapThreshold = 10;
  private timeThreshold = 15000; // 15 seconds in milliseconds
  private testModeKey = '__env_ovr';

  constructor(private alertController: AlertController) { }

  tap() {
    const now = Date.now();
    this.taps = this.taps.filter(tap => now - tap <= this.timeThreshold);
    this.taps.push(now);
    
    // Check if the app is in test mode
    const isTestMode = localStorage.getItem(this.testModeKey) === 'test';

    if (isTestMode) {
      this.askToDisableTestMode();
    } else if (this.taps.length >= this.tapThreshold) {
      this.askToEnableTestMode();
    }
  }

  private async askToEnableTestMode() {
    const alert = await this.alertController.create({
      header: 'Enable Test Mode?',
      message: 'Do you want to enable test mode?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: () => {
            localStorage.setItem(this.testModeKey, 'test');
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }

  private async askToDisableTestMode() {
    const alert = await this.alertController.create({
      header: 'Disable Test Mode?',
      message: 'Do you want to disable test mode?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: () => {
            localStorage.removeItem(this.testModeKey);
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }
}
