import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { EMPTY, Observable, Subject, throwError, catchError,
  finalize,
  retry,
  switchMap,
  tap, } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LoginService } from "src/app/main/authentication/login/login.service";
import { RefreshService } from "../services/refresh.service";
import { PresentersService } from "../services/presenters.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  // private local = environment.e2e;

  constructor(
    private loginService: LoginService,
    private refreshService: RefreshService,
    private presenterService: PresentersService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    // }
    return next.handle(request).pipe(
      retry(1),
      catchError((error) => this.handleResponseError(error, request, next))
    ) as Observable<HttpEvent<any>>;
  }

  addAuthHeader(request) {
    const authHeader = localStorage.getItem("token");
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: authHeader,
        },
      });
    }
    return request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.refreshService.refreshWithRefreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next({});
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.logout();
          return EMPTY;
        })
      );
    }
  }

  logout() {
    this.loginService.signOut();
  }

  handleResponseError(error, request?, next?) {
    if (error.error instanceof ErrorEvent) {
      // client-side error

      return throwError(() => new Error(error));
    } else {
      // server-side error

      if (error.status === 0) {
        return "Error connecting to server, try again later.";
      } else {
        if (error.status === 406) {
          if (error.error.includes("[Token is expired]")) {
            localStorage.removeItem("token");
            return this.refreshToken().pipe(
              switchMap(() => {
                request = this.addAuthHeader(request);
                return next.handle(request);
              }),
              catchError((e) => {
                if (!error.error.includes("[Token is expired]")) {
                  return this.handleResponseError(e);
                } else {
                  this.logout();
                }
              })
            );
          } else {
            this.logout();
          }
          // Not Acceptable token, Invalid Sig or Segments # etc
          // Log Out the User.
          return EMPTY;
        } else if (error.status === 428) {
          // No Token Used.
          // Log Out the User.
          this.showSnackBar(error.error.text);
          this.logout();
          return EMPTY;
        } else if (error.status === 412) {
          // user not found
          this.showSnackBar(error.error.text || error.error);
          return throwError(error);
        } else if (error.status === 403) {
          // status forbidden dont have access
          this.showSnackBar(error.error.text);
          this.logout();
          return EMPTY;
        } else if (error.status === 401) {
          if (error.error.includes("Privilege Error")) {
            this.showSnackBar(error.error);
            return throwError(error);
          } else {
            // status Authorization Error
            this.showSnackBar(error.error.text || error.error);
            this.logout();
            return EMPTY;
          }
        } else if (error.status === 502) {
          // status Authorization Error
          this.showSnackBar(error.error.text);
          this.logout();
          return EMPTY;
        } else if (error.status === 404) {
          if (error.url.includes("roster/1.1/assessment/resource")) {
            return EMPTY;
          }
        }

        return throwError(error);
      }
    }
  }

  showSnackBar(text) {
    this.presenterService.presentToast({
      message: text,
      color: "danger",
      duration: 3000,
      position: "top",
      icon: "alert-circle-outline",
    });
  }
}
