import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Platform } from "@ionic/angular";
import { Camera, CameraOptions, CameraResultType, CameraSource } from "@capacitor/camera";
import { UtilService } from "../../services/util.service";
import { PresentersService } from "../../services/presenters.service";
import { format } from "date-fns";
import { Buffer } from "buffer";
import { FilePicker } from '@capawesome/capacitor-file-picker';

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements OnInit {
  // @Input() background: any;
  @Output() public imageHandler = new EventEmitter<any>();
  @Input() public accept;
  @Input() public hidden = false;
  @ViewChild("inputcamera", { static: true }) public inputcamera: ElementRef;

  constructor(
    public presenters: PresentersService,
    public platform: Platform,
    public util: UtilService
  ) {}

  ngOnInit() {
    console.log(this.accept);
  }

  imgOnChange(event) {
    console.log("img on change called");

    if (event.target.files[0]) {
      console.log("img on change got something");
      const reader = new FileReader();
      const fileName = event.target.files[0].name;
      const fileType = event.target.files[0].type;
      reader.onload = (r: any) => {
        console.log(r);
        const base64 = r.target.result as string;
        this.emitImage(base64, {
          name: fileName,
          type: fileType,
          extension: fileName.split(".")[1],
          size: r.total,
          isBase64: true,
        });
      };
      if (event.srcElement.files.length > 0) {
        reader.readAsDataURL(event.srcElement.files[0]);
      }
    }
  }

  emitImage(img, meta) {
    this.imageHandler.emit({ img, metadata: meta });
  }

  nativeCameraCheck(e) {
    console.log(e);
    if (this.platform.is("capacitor")) {
      e.stopPropagation();
      e.preventDefault();
      this.imageSourceActionsheet();
    }
  }

  async imageSourceActionsheet() {
    let buttons: any = [];

    if (this.accept.includes("capture=camera")) {
      buttons.push({
        text: "Take Photo",
        icon: "camera",
        handler: async () => {
          console.log("Camera clicked");
          this.openCamera();
        },
      });
    }

    if (this.accept.includes("image/")) {
      buttons.push({
        text: "Choose Photo",
        icon: "images-outline",
        handler: async () => {
          console.log("Gallery clicked");
          this.openGallery();
        },
      });
    }

    if (
      this.accept.includes("pdf") ||
      this.accept.includes("image/") ||
      this.accept.includes("video/") ||
      this.accept.includes("audio/")
    ) {
      buttons.push({
        text: "Browse...",
        icon: "document-outline",
        handler: async () => {
          console.log("Files clicked");
          this.openFiles(this.accept);
        },
      });
    }
    console.log(buttons);
    if (buttons.length == 1) {
      buttons[0].handler();
    } else {
      buttons.push({
        text: "Cancel",
        role: "cancel",
        handler: () => {
          console.log("Cancel clicked");
        },
      });
      this.presenters.presentActionsheet({
        buttons,
      });
    }
    console.log(buttons);
  }

  // async openFiles() {
  //   // if(this.platform.is('android')) {
  //   //   await this.util.checkAndroidPermissions(['READ_EXTERNAL_STORAGE']);
  //   // }
  //   this.chooser.getFile('application/pdf')
  //   .then(fileData => {
  //     console.log('fileData: ', fileData);
  //     this.emitImage(fileData.dataURI, {name: fileData.name, type: fileData.mediaType, isBase64: true});
  //   })
  //   .catch(e => console.log(e));
  // }

  async openFiles(type) {
    // if(this.platform.is('android')) {
    //   await this.util.checkAndroidPermissions(['READ_EXTERNAL_STORAGE']);
    // }
  
    const result = await FilePicker.pickFiles({
      readData: true
    });
    const pickedFile = result.files[0];    
    const file = Buffer.from(pickedFile.data);
    this.emitImage(pickedFile.data, {
      name: pickedFile.name,
      type: pickedFile.mimeType,
      extension: pickedFile.name.split(".")[1],
      size: file.length,
      isBase64: false,
    });
  }

  async openGallery() {
    const imgData =await Camera
      .getPhoto( {
        quality: 100,
        resultType: CameraResultType.Uri,
        allowEditing: true,
      })

      this.imageUrlToBase64(imgData.webPath)
      .then(
        (imageData: any) => {
          console.log("imageData: ", imageData);
          if (imageData) {
            const file = Buffer.from(imageData, "base64");
            console.log("FileSize: " + file.length);
            this.emitImage(imageData, {
              name: "gallery-" + format(new Date(), "yyyyMMccHHmmss") + ".jpg",
              type: "image/jpeg",
              extension: "jpg",
              size: file.length,
              isBase64: true,
            });
          }
        },
        (err) => {
          console.log("imageData err: ", err);
        }
      )
      .catch((e) => console.log(e));
  }

  async imageUrlToBase64(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };
  

  async openCamera() {
    if (this.platform.is("android")) {
      // await this.util.checkAndroidPermissions(["CAMERA"]);
    }
    
    const imgData = await Camera
      .getPhoto( {
        quality: 100,
        resultType: CameraResultType.Uri,
        allowEditing: true,
      });
    
    this.imageUrlToBase64(imgData.webPath)
      .then(
        (imageData: any) => {
          console.log("imageData: ", imageData);
          if (imageData) {
            const file = Buffer.from(imageData, "base64"); //encode image into bytes
            console.log("FileSize: " + file.length);
            this.emitImage(imageData, {
              name: "camera-" + format(new Date(), "yyyyMMccHHmmss") + ".jpg",
              type: "image/jpeg",
              extension: "jpg",
              size: file.length,
              isBase64: true,
            });
          }
        },
        (err) => {
          console.log("imageData err: ", err);
        }
      )
      .catch((e) => console.log(e));
  }

  click() {
    console.log("clcikedkeid");
    this.inputcamera.nativeElement.click();
  }
}
