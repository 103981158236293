export class AccreditationFakeDb {
  public static accreditations = [
    {
      id: "5e6ed43e38e3816c08d0b8e1",
      category: "Security License",
      credentials: [
        {
          accreditation: "NSW Security License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "1A - Unarmed Guard",
                "1B - Bodyguard",
                "1C - Crowd Controller",
                "1D - Guard Dog Handler",
                "1E - Monitoring Center Operator",
                "1F - Armed Guard",
                "2A - Security Consultant",
                "2B - Security Seller",
                "2C - Security Equipment Specialist",
                "2D - Security Trainer",
                "MA - Self-employed \u0026 holds Class 1 or 2 license or both and provides own service",
                "MB - Provides no more than 3 persons on any one day",
                "MC - Provides no more than 14 persons on any one day",
                "MD - Provides no more than 49 persons on any one day",
                "ME - Unlimited persons",
              ],
            },
          ],
          validation: {
            api: "security/nsw",
            outputs: [
              {
                label: "Expires At",
                type: "date",
                choices: null,
              },
              {
                label: "License Number",
                type: "string",
                choices: null,
              },
            ],
          },
        },
        {
          accreditation: "VIC Security Licence",
          inputs: [
            {
              label: "License Number",
              type: " string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "QLD Security Licence",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "WA Security Number",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "Crowd Control Agent",
                "Crowd Control",
                "Inquiry Agent",
                "Investigator",
                "Security Agent",
                "Security Bodyguard",
                "Security Consultant",
                "Security Installer",
                "Security Officer",
                "Security Company",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed46538e3816c08d0b8e2",
      category: "RSA License",
      credentials: [
        {
          accreditation: "NSW Responsible Service of Alcohol",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "Class A - Responsible Service of Alcohol",
                "Class G - Responsible Conduct of Gambling",
                "Class H - High Risk Venue Management",
                "Class P - Privacy Training",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Card", "Interim", "Certificate"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "VIC Responsible Service of Alcholol",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "QLD Responsible Service of Alcholol",
          inputs: [
            {
              label: "License Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "WA Responsible Service of Alcholol",
          inputs: [
            {
              label: "License Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed47738e3816c08d0b8e3",
      category: "RSG License",
      credentials: [
        {
          accreditation: "NSW Responsible Service of Alcohol",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "Class A - Responsible Service of Alcohol",
                "Class G - Responsible Conduct of Gambling",
                "Class H - High Risk Venue Management",
                "Class P - Privacy Training",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Card", "Interim", "Certificate"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "VIC Responsible Service of Gaming",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Module 1", "Module 2"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "QLD Responsible Service of Gambling",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed48b38e3816c08d0b8e4",
      category: "RSA Privacy License",
      credentials: [
        {
          accreditation: "NSW Responsible Service of Alcohol",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "Class A - Responsible Service of Alcohol",
                "Class G - Responsible Conduct of Gambling",
                "Class H - High Risk Venue Management",
                "Class P - Privacy Training",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Card", "Interim", "Certificate"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed4df38e3816c08d0b8e5",
      category: "Liquor License",
      credentials: [
        {
          accreditation: "NSW Liquor  License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "VIC Liquor License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "QLD Liquor License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "WA Liquor License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: [
                "Club",
                "Club Restricted",
                "Hotel",
                "Hotel Restricted",
                "Tavern",
                "Tavern Restricted",
                "Small Bar",
                "Casino Liquor Licence",
                "Special Facility",
                "Producer",
                "NightClub",
                "Occasional",
                "Restaurant",
                "Wholesaler",
                "Liquor Stores",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed4f538e3816c08d0b8e6",
      category: "Driver License",
      credentials: [
        {
          accreditation: "NSW Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "VIC Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "NT Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "SA Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "WA Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "ACT Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Qld Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Tas Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Int'l Driver License",
          inputs: [
            {
              label: "License Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "C - Car",
                "R - Rider",
                "HC - Heavy Combination",
                "HR - Heavy Rigid",
                "LR - Light Rigid",
                "MC - Multi Combination",
                "MR - Medium Rigid",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Full", "Learners", "Provisional"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed73038e3816c08d0b8e8",
      category: "Other Individual Accreditation",
      credentials: [
        {
          accreditation: "Traffic Control",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Class",
              type: "multi-choice-string",
              choices: [
                "Red - Select \u0026 modify traffic control plans",
                "Blue - Control traffic with a stop/slow bat",
                "Orange - Design \u0026 inspect traffic control plans",
                "Yellow - Implement traffic management plan",
              ],
            },
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Card", "Interim", "Certificate"],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "General Construction Induction Card",
          inputs: [
            {
              label: "Starts At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Royal Life Saving",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Australia Pool Lifeguard Certificate",
                "Australia Pool Lifeguard Licence",
                "Royal Life Saving Bronze Medallion",
                "Royal Life Saving Resuscitation",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Fire Safety Officer",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "First Aid",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Public Liability Insurance",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Work Cover",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Visa",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Certificate in Security Operation",
          inputs: [
            {
              label: "Type",
              type: "single-choice-string",
              choices: ["Cert 1", "Cert 2", "Cert 3"],
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "5e6ed74f38e3816c08d0b8e9",
      category: "Other Organisation Accreditation",
      credentials: [
        {
          accreditation: "Public Liability Insurance",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Work Cover",
          inputs: [
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
    {
      id: "614aad8b17773d0142f20b40",
      category: "Personal Identification",
      credentials: [
        {
          accreditation: "NSW Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "VIC Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "NT Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "SA Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "WA Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "ACT Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Qld Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Tas Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Int'l Proof of Age",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Status",
              type: "single-choice-string",
              choices: [
                "Current",
                "Expired",
                "Cancelled",
                "Withdrawn",
                "Surrendered",
                "Suspended",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: " Medicare",
          inputs: [
            {
              label: "Card Number",
              type: "string",
              choices: null,
            },
            {
              label: "Individual Reference Number",
              type: "string",
              choices: null,
            },
            {
              label: "Card Colour",
              type: "single-choice-string",
              choices: ["Green", "Blue", "Yellow"],
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
        {
          accreditation: "Passport",
          inputs: [
            {
              label: "Document Number",
              type: "string",
              choices: null,
            },
            {
              label: "Expires At",
              type: "date",
              choices: null,
            },
            {
              label: "Place of Birth",
              type: "string",
              choices: null,
            },
            {
              label: "Nationality",
              type: "string",
              choices: null,
            },
            {
              label: "Country of Issue",
              type: "single-choice-string",
              choices: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "Andorra",
                "Angola",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bhutan",
                "Bolivia",
                "Bosnia and Herzegovina",
                "Botswana",
                "Brazil",
                "Brunei",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde Islands",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Colombia",
                "Comoro Islands",
                "Congo (Dem. Rep.)",
                "Congo (Rep.)",
                "Costa Rica",
                "Cote d'Ivoire",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Fiji",
                "Finland",
                "France",
                "Gabon",
                "Georgia",
                "Germany",
                "Ghana",
                "Greece",
                "Grenada",
                "Guatemala",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Honduras",
                "Hong Kong (SAR China)",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland",
                "Israel",
                "Italy",
                "Jamaica",
                "Japan",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Kosovo",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macao (SAR China)",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Mauritania",
                "Mauritius",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "North Korea",
                "North Macedonia",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau Islands",
                "Palestinian Territory",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Poland",
                "Portugal",
                "Qatar",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Korea",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "St. Kitts and Nevis",
                "St. Lucia",
                "St. Vincent and the Grenadines",
                "Sudan",
                "Suriname",
                "Sweden",
                "Switzerland",
                "Syria",
                "Taiwan (Chinese Taipei)",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "The Gambia",
                "Timor-Leste",
                "Togo",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Vatican City",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Zambia",
                "Zimbabwe",
                "eSwatini",
              ],
            },
          ],
          validation: {
            api: "",
            outputs: null,
          },
        },
      ],
    },
  ];

  public static securityLicenses = {
    licenceNumber: "000212785",
    status: "Current",
    startDate: "17/09/2014",
    expiryDate: "16/11/2023",
    refusedDate: "",
    licenceType: "Operator Security Licence",
    licenceName: "",
    licensee: "Neda Alimohammadloo",
    categories: "",
    classes: [
      {
        className: "1A",
        isActive: true,
        description: "1A Unarmed Guard",
        startDate: "17/09/2014",
        endDate: "16/11/2023",
      },
      {
        className: "1C",
        isActive: true,
        description: "1C Crowd Controller",
        startDate: "17/09/2014",
        endDate: "16/11/2023",
      },
    ],
  };

  public static userAccreditations = [];
}
