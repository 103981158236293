export class AppSetting {
  public static USER_ROLE_RESOURCE = "resource";
  public static USER_ROLE_VENUE_MANAGER = "venue-manager";
  public static USER_ROLE_SUPERVISING_RESOURCE = "supervising-resource";
}

export class TaskStatus {
  public static TASK_STATUS_PENDING = 1;
  public static TASK_STATUS_DECLINED = 3;
  public static TASK_STATUS_ACCEPT = 2;
  public static TASK_NOT_SHARED = 0
}

export class ScheduleSetting {
  public static TIME_LIMIT_BEFORE_START = 2;
  public static TIME_LIMIT_AFTER_START = 4;
  public static TIME_LIMIT_AFTER_END = 4;
  public static TIME_LIMIT_INCIDENT_REPORT = 6;

  static isEmpty(value) {
    if (value && value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  static isValidDate(dateStr) {
    if(!dateStr) {
      return false;
    }
    if(dateStr == "0001-01-01T00:00:00Z") {
      return false;
    }
    return true;
  }

}