import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { NavController, ModalController } from "@ionic/angular";
import { PresentersService } from "src/app/shared/services/presenters.service";
import { ProfileService } from "../../profile/profile.service";

@Component({
  selector: "app-auth-account",
  templateUrl: "./auth-account.page.html",
  styleUrls: ["./auth-account.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthAccountPage implements OnInit {
  formAccount: UntypedFormGroup;
  accountType = "";
  constructor(
    private navController: NavController,
    private presenters: PresentersService,
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private modalController: ModalController
  ) {
    this.createRadioForm();
  }

  ngOnInit() {}

  createRadioForm() {
    this.profileService.accountRole.subscribe((value) => {
      this.accountType = value;
    });
  }

  handleChangeType(evt) {
    this.accountType = evt.detail.value;
  }

  handleApply(evt) {
    this.profileService.setUserRole(this.accountType);
    this.modalController.dismiss();
    // this.navController.navigateForward([`/auth/auth-method`]);
  }
}
