import { Geolocation } from "@capacitor/geolocation";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injectable,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpErrorResponse,
} from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { isPlatform, ModalController, Platform } from "@ionic/angular";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@capacitor/status-bar";
import * as Sentry from "@sentry/angular-ivy";
import { Integrations } from "@sentry/tracing";
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
  Dedupe as DedupeIntegration,
} from "@sentry/integrations";
import { InMemoryWebApiModule } from "angular-in-memory-web-api";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FakeDbService } from "./fake-db/fake-db.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AppInterceptor } from "./shared/interceptor/app.interceptor";
import { AuthGuard } from "./shared/guard/auth.guard";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { FileUploadComponentModule } from "./shared/components/file-upload/file-upload.module";
import { UtilService } from "./shared/services/util.service";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { VERSION } from "src/environments/version";
import { PreferencePageModule } from "./main/preference/preference.module";
import { ConfigurationService } from "./shared/services/configuration.service";

if (environment.production || environment.stage || environment.test) {
  Sentry.init({
    dsn: environment["sentryDsn"],
    environment: environment.env,
    debug: false,
    release: `taskezy@${VERSION.version}.${VERSION.abbreviatedSha}`,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ["*"],
        routingInstrumentation: Sentry.routingInstrumentation,
      })
      // new Sentry.Integrations.TryCatch({
      //   XMLHttpRequest: false,
      // }),
      // new Integrations.BrowserTracing({
      // tracingOrigins: ["*"],
      // routingInstrumentation: Sentry.routingInstrumentation,
      // }),
      // new ExtraErrorDataIntegration({ depth: 8 }),
      // new CaptureConsoleIntegration({
      //   levels: ["warn", "error", "debug", "assert"],
      // }),
      // new DedupeIntegration(),
    ],
    tracesSampleRate: 0.3,
    attachStacktrace: true,
    normalizeDepth: 8,
  });
}

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor(private platform: Platform) {}
//   extractError(error) {
//     // Try to unwrap zone.js error.
//     // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
//     if (error && error.ngOriginalError) {
//       error = error.ngOriginalError;
//     }

//     // We can handle messages and Error objects directly.
//     if (typeof error === "string" || error instanceof Error) {
//       return error;
//     }

//     // If it's http module error, extract as much information from it as we can.
//     if (error instanceof HttpErrorResponse) {
//       // The `error` property of http exception can be either an `Error` object, which we can use directly...
//       if (error.error instanceof Error) {
//         return error.error;
//       }

//       // ... or an`ErrorEvent`, which can provide us with the message but no stack...
//       if (error.error instanceof ErrorEvent) {
//         return error.error.message;
//       }

//       // ...or the request body itself, which we can use as a message instead.
//       if (typeof error.error === "string") {
//         return `Server returned code ${error.status} with body "${error.error}"`;
//       }

//       // If we don't have any detailed information, fallback to the request message itself.
//       return error.message;
//     }

//     // Skip if there's no error, and let user decide what to do with it.
//     return null;
//   }

//   handleError(error) {
//     const extractedError = this.extractError(error) || "Handled unknown error";
//     const eventId = Sentry.captureException(extractedError);
//     if (environment.dev || environment.e2e) {
//       console.error(extractedError);
//     } else {
//       Sentry.showReportDialog({ eventId });
//     }
//   }
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonIcon: "arrow-back",
      backButtonText: "",
      animated: true,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
      post204: false,
      put204: false,
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !environment.e2e,
    }),
    IntercomModule.forRoot({
      appId: environment.intercomAPIKey,
      updateOnRouterChange: true,
    }),
    FileUploadComponentModule,
    PreferencePageModule,
  ],
  providers: [
    FakeDbService,
    ModalController,
    AuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: !environment.production,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    UtilService,
    {
      provide: ConfigurationService,
      useFactory: () => {
        const configService = new ConfigurationService();

        let env = environment.env;

        if(env != 'e2e-resource') {
          try {
            const localStorageEnv = localStorage.getItem("__env_ovr");
            if (localStorageEnv) {
              env = localStorageEnv;
            }
          } catch (error) {
            console.error('Failed to access localStorage', error);
          }
        }

        configService.initialize(env);
        return configService;
      }
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
