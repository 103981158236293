import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  UntypedFormControl,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  NavController,
  ModalController,
  AlertController,
} from "@ionic/angular";
import { PresentersService } from "src/app/shared/services/presenters.service";
import { ProfileService } from "../profile/profile.service";
import { Subject, takeUntil } from "rxjs";
import { LoginService } from "../authentication/login/login.service";
import { environment } from "src/environments/environment";

const VIEW_MODE_SETTING = "VIEW_MODE_SETTING";
const VIEW_MODE_DELETE_FORM = "VIEW_MODE_DELETE_FORM";

@Component({
  selector: "app-preference",
  templateUrl: "./preference.page.html",
  styleUrls: ["./preference.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PreferencePage implements OnInit {
  formAccount: UntypedFormGroup;
  unsubscribeAll = new Subject<any>();
  themeDark;
  locationCheck;
  viewMode = VIEW_MODE_SETTING;
  accountForm: UntypedFormGroup;
  accountEmail = "";
  isProduction = environment.production;
  isTestMode = false;

  constructor(
    private profileService: ProfileService,
    private modalController: ModalController,
    private alertController: AlertController,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService
  ) {
    this.isTestMode = localStorage.getItem("__env_ovr") === "test";
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      email: new UntypedFormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.createAccountForm();
    this.profileService.theme.subscribe((value) => {
      if (value === "dark") {
        this.themeDark = true;
      } else {
        this.themeDark = false;
      }
    });

    this.profileService.locationCheck.subscribe((value) => {
      this.locationCheck = value;
    });
    this.profileService.currentUserProfile
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((currProf) => {
        this.accountEmail = currProf?.email;
      });
  }

  handleChangeTheme(evt) {
    this.themeDark = evt.target.checked;
    this.profileService.setTheme(this.themeDark ? "dark" : "light");
  }

  handleChangeLocation(evt) {
    this.locationCheck = evt.target.checked;
    this.profileService.setLocationCheck(this.locationCheck);
  }

  handleBack() {
    if (this.viewMode === VIEW_MODE_DELETE_FORM) {
      this.viewMode = VIEW_MODE_SETTING;
      return;
    }
    this.modalController.dismiss();
  }

  async handleDelete() {
    this.viewMode = VIEW_MODE_DELETE_FORM;
  }

  getTitle() {
    if (this.viewMode === VIEW_MODE_DELETE_FORM) {
      return "Delete Account";
    }

    return "Preference";
  }

  handleDeleteAccount() {
    this.profileService.deleteAccount("taskezy").subscribe((res) => {
      this.loginService.signOut();
      this.modalController.dismiss();
    });
  }
}
