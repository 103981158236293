import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private _apiUrl = 'https://devapi.gravityfusion.com.au'; // default value
  private _docUrl = 'https://devdoc.gravityfusion.com.au'; // default value
  private _siteUrl = 'https://app-dev.taskezy.com'; // default value


    private prodUrl = 'https://api.gravityfusion.com.au';
    private stageUrl = 'https://stageapi.gravityfusion.com.au';
    private testUrl = 'https://testapi.gravityfusion.com.au';
    private devUrl = 'https://devapi.gravityfusion.com.au';
    private e2eUrl = '';

    private prodDocUrl = 'https://doc.gravityfusion.com.au';
    private stageDocUrl = 'https://stagedoc.gravityfusion.com.au';
    private testDocUrl = 'https://testdoc.gravityfusion.com.au';
    private devDocUrl = 'https://devdoc.gravityfusion.com.au';
    private e2eDocUrl = '';

    private prodSiteUrl = 'https://app.taskezy.com';
    private stageSiteUrl = 'https://app-stage.taskezy.com';
    private testSiteUrl = 'https://app-test.taskezy.com';
    private devSiteUrl = 'https://app-dev.taskezy.com';
    private e2eSiteUrl = '';

  get apiUrl(): string {
    return this._apiUrl;
  }
  get docUrl(): string {
    return this._docUrl;
  }
  get siteUrl(): string {
    return this._siteUrl;
  }
  
  set apiUrl(value: string) {
    this._apiUrl = value;
  }
  set docUrl(value: string) {
    this._docUrl = value;
  }
  set siteUrl(value: string) {
    this._siteUrl = value;
  }

  setProd() {
    this.apiUrl = this.prodUrl;
    this.docUrl = this.prodDocUrl;
    this.siteUrl = this.prodSiteUrl;
  }

  setStage() {
    this.apiUrl = this.stageUrl;
    this.docUrl = this.stageDocUrl;
    this.siteUrl = this.stageSiteUrl;
  }

  setTest() {
    this.apiUrl = this.testUrl;
    this.docUrl = this.testDocUrl;
    this.siteUrl = this.testSiteUrl;
  }

  setDev() {
    this.apiUrl = this.devUrl;
    this.docUrl = this.devDocUrl;
    this.siteUrl = this.devSiteUrl;
  }

  setE2E() {
    this.apiUrl = this.e2eUrl;
    this.docUrl = this.e2eDocUrl;
    this.siteUrl = this.e2eSiteUrl;
  }

  initialize(env: string) {
    console.log(env);
    if (env.includes('prod')) {
      this.setProd();
    } else if (env.includes('test')) {
      this.setTest();
    } else if (env.includes('stage')) {
      this.setStage();
    } else if (env.includes('dev')) {
      this.setDev();
    } else if (env.includes('e2e')) {
      this.setE2E();
    } else {
      this.setDev();
    }
  }
}