import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthAccountGuard } from "./shared/guard/authAccount.guard";
import { AuthGuard } from "./shared/guard/auth.guard";
import { NoAuthGuard } from "./shared/guard/noAuth.guard";

const routes: Routes = [
  {
    path: '',
    pathMatch : 'full',
    redirectTo: "/dashboard",
  },
    {
      path: "dashboard",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      children: [
        {
          path: "",
          loadChildren: () =>
            import("./main/dashboard/dashboard.module").then(
              (m) => m.DashboardPageModule
            ),
        },
      ],
    },
    {
      path: "schedule",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      children: [
        {
          path: "",
          loadChildren: () =>
            import("./main/schedule/schedule.module").then(
              (m) => m.SchedulePageModule
            ),
        },
      ],
    },
    {
      path: "incidents",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      children: [
        {
          path: "",
          loadChildren: () =>
            import("./main/incidents/incidents.module").then(
              (m) => m.IncidentsPageModule
            ),
        },
      ],
    },
    {
      path: "profile",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      loadChildren: () =>
        import("./main/profile/profile.module").then((m) => m.ProfilePageModule),
    },
    {
      path: "connections",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      loadChildren: () =>
        import("./main/connection/connection.module").then(
          (m) => m.ConnectionPageModule
        ),
    },
    // {
    //   path: "member-profile",
    //   canActivate: [AuthAccountGuard],
    //   canActivateChild: [AuthAccountGuard],
    //   loadChildren: () =>
    //     import("./main/member-profile/member-profile.module").then(
    //       (m) => m.MemberProfilePageModule
    //     ),
    // },
    // {
    //   path: "attendance",
    //   canActivate: [AuthAccountGuard],
    //   canActivateChild: [AuthAccountGuard],
    //   loadChildren: () =>
    //     import("./main/attendance/attendance.module").then(
    //       (m) => m.AttendancePageModule
    //     ),
    // },
    // {
    //   path: "patron-number",
    //   canActivate: [AuthAccountGuard],
    //   canActivateChild: [AuthAccountGuard],
    //   loadChildren: () =>
    //     import("./main/patron-number/patron-number.module").then(
    //       (m) => m.PersonalIdPageModule
    //     ),
    // },
    {
      path: "resource-detail",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      loadChildren: () =>
        import("./main/resource-detail/resource-detail.module").then(
          (m) => m.ResourceDetailPageModule
        ),
    },
    // {
    //   path: "personal-id",
    //   loadChildren: () =>
    //     import("./main/personal-id/personal-id.module").then(
    //       (m) => m.PersonalIdPageModule
    //     ),
    // },
    // {
    //   path: "schedule-break",
    //   canActivate: [AuthAccountGuard],
    //   canActivateChild: [AuthAccountGuard],
    //   loadChildren: () =>
    //     import("./main/schedule-break/schedule-break.module").then(
    //       (m) => m.ScheduleBreakPageModule
    //     ),
    // },
    {
      path: "accreditation",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      loadChildren: () =>
        import("./main/accreditation/accreditation.module").then(
          (m) => m.AccreditationPageModule
        ),
    },
    {
      path: "report",
      canActivate: [AuthAccountGuard],
      canActivateChild: [AuthAccountGuard],
      loadChildren: () =>
        import("./main/report/report.module").then((m) => m.ReportPageModule),
    },
    // {
    //   path: "notification",
    //   canActivate: [AuthAccountGuard],
    //   canActivateChild: [AuthAccountGuard],
    //   loadChildren: () =>
    //     import("./main/notification/notification.module").then(
    //       (m) => m.NotificationPageModule
    //     ),
    // },
    // {
    //   path: "",
    //   redirectTo: "",
    //   pathMatch: "full",
    // },
  {
    path: "auth",
    // canActivate: [NoAuthGuard],
    loadChildren: () =>
      import("./main/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "**",
    redirectTo: "/auth/login",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
