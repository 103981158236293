import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, BehaviorSubject, of, combineLatest } from "rxjs";
import { switchMap, tap, map } from "rxjs/operators";
import { v4 as _uuid } from "uuid";
import { environment } from "src/environments/environment";
import { AppSetting } from "src/app/setting/app-setting";
import { ConfigurationService } from "src/app/shared/services/configuration.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  currentAccount = new BehaviorSubject<any>({});
  currentUserProfile = new BehaviorSubject<any>({});
  userAccounts = new BehaviorSubject<any>([]);
  // userAccountList = [];
  // userProfile: any = {};
  accountRole = new BehaviorSubject<any>("");
  venueManager = new BehaviorSubject<any>("");
  theme = new BehaviorSubject<any>("");
  locationCheck = new BehaviorSubject<boolean>(true);
  tenant = environment.tenant;
  private local = environment.e2e;
  private endPointAccount;
  private endPointUserProfile;
  public endPointFiles;

  constructor(
    private http: HttpClient,
    private configService: ConfigurationService
  ) {
    this.endPointFiles = this.configService.docUrl;
    this.endPointAccount =
      this.configService.apiUrl + environment.apiUrlAccount;
    this.endPointUserProfile =
      this.configService.apiUrl + environment.apiUrlAuth;

      console.log(this.endPointUserProfile);
      console.log(this.endPointAccount);
    // AppSetting.USER_ROLE_VENUE_MANAGER;
    // AppSetting.USER_ROLE_SUPERVISING_RESOURCE;
    // AppSetting.USER_ROLE_RESOURCE;
    this.accountRole.next(AppSetting.USER_ROLE_RESOURCE);
    this.loadSetting();
  }

  loadSetting() {
    this.theme.next(localStorage.getItem("theme"));
  }

  initServiceValue() {
    this.currentAccount.next(null);
    this.currentUserProfile.next(null);
  }

  getUserProfileAndAccounts() {
    // this.getAccounts().subscribe((userAccounts) => {
    //   console.log(userAccounts);
    //   // we can add a selectionhere if required or something
    //   this.userAccounts.next(userAccounts);
    //   // this.userAccountList = userAccounts;
    //   // this.checkDefaultAccount();
    // });

    // this.getProfile().subscribe((profile) => {
    //   console.log(profile);
    //   if ((profile.statusCode = 200)) {
    //     this.currentUserProfile.next(profile.user);
    //     // this.userProfile = profile;
    //     this.checkDefaultAccount();
    //   }
    // });

    return combineLatest(this.getAccounts(), this.getProfile()).pipe(
      tap((res) => {
        console.log(res[0]);
        console.log(res[1]);
        this.userAccounts.next(res[0]);
        if ((res[1].statusCode = 200)) {
          this.currentUserProfile.next(res[1].user);
          // this.userProfile = profile;
          this.checkDefaultAccount();
        }
      })
    );
  }

  setUserRole(role) {
    this.accountRole.next(role);
  }

  setVenueManager(manager) {
    this.venueManager.next(manager)
  }

  setTheme(theme) {
    this.theme.next(theme);
    localStorage.setItem("theme", theme);
  }

  setLocationCheck(location) {
    this.locationCheck.next(location);
  }

  checkDefaultAccount() {
    const userProfile = this.currentUserProfile.getValue();
    const userAccountList = this.userAccounts.getValue();
    console.log("1");
    if (
      userProfile &&
      userProfile.data &&
      userProfile.data[this.tenant] &&
      userProfile.data[this.tenant].defaultAccount
    ) {
      console.log("2");
      const defaultAccount = userAccountList.find(
        (accountItem) =>
          accountItem.uuid === userProfile.data[this.tenant].defaultAccount
      );
      if (defaultAccount) {
        console.log("3");
        this.currentAccount.next(defaultAccount);
      }
    } else {
      console.log("4");
      if (userAccountList && userAccountList.length > 0) {
        console.log("5");
        this.currentAccount.next(userAccountList[0]);
      } else {
        console.log("6");
        this.currentAccount.next({});
      }
    }
    console.log("7");
    console.log(this.currentAccount.getValue());
  }

  setDefaultAccount(accountId) {
    return this.http.get(
      `${this.endPointUserProfile}/${this.tenant}/${accountId}`
    );
  }

  getAccounts(): Observable<any> {
    if (this.local) {
      const user_id = localStorage.getItem("user_id");
      return this.http.get("/api/accounts").pipe(
        switchMap((res: any) => {
          // console.log('---------: ', res);
          // console.log(typeof user_id);
          // console.log(user_id);
          // if (user_id === '11111111' || user_id === '11111111') {
          const filtered = res.filter(
            (acnt: any) =>
              acnt.members.filter(
                (mem: any) => mem.user_id.toString() === user_id
              ).length > 0
          );
          console.log(filtered);
          return of(filtered);
          // } else {
          //   return of([]);
          // }
        })
      );
    } else {
      //TODO: this might not work as this api returns the accounts the user is an ADMIN for

      return this.http.get(`${this.endPointAccount}/list`);
    }
  }

  // getProfileSingle(uuid): Observable<any> {
  //   if (this.local) {
  //     return this.http.get("/api/userprofile/" + uuid);
  //   } else {
  //     return this.http.get(`${this.endPointUserProfile}/${uuid}`);
  //   }
  // }

  getProfile(): Observable<any> {
    if (this.local) {
      const userId = localStorage.getItem("refreshToken");
      return this.http
        .get(`${this.endPointUserProfile}/${userId}`)
        .pipe(map((login: any) => login));
    } else {
      return this.http.get(`${this.endPointUserProfile}/user`);
    }
  }

  getAccountMembers(uuid) {
    return this.http.get(`${this.endPointAccount}/${uuid}/members`)
  }

  getAccountSingle(uuid): Observable<any> {
    return this.http.get(`${this.endPointAccount}/${uuid}`);
  }

  setCurrentAccount(uuid) {
    if (uuid) {
      // this.invitationService.getListInvites(uuid);
      return this.getAccountSingle(uuid).pipe(
        tap((res) => {
          localStorage.setItem("currentAccount", uuid);
          this.currentAccount.next(res);
        })
      );
    } else {
      of({});
      // TODO: need to find where we will save this now
    }
  }

  updateProfileAvatar(avatar): Observable<any> {
    if (this.local) {
      return this.http.get(`api/userprofile/`).pipe(
        switchMap((res: any) => {
          res.avatar = avatar;
          return this.http.put(`api/userprofile/`, res);
        })
      );
    } else {
      // TODO: update to use correct end points
      // this.endPointUserProfile/user
      // {imageUrl:string}
      // to save the avatar
      return this.http.put(`${this.endPointUserProfile}/user`, {
        imageUrl: avatar,
      });
    }
  }

  updateProfile(profile): Observable<any> {
    if (this.local) {
      return this.http.put(`${this.endPointUserProfile}/user`, profile).pipe(
        switchMap((res) => {
          return this.getProfile();
        }),
        tap((res) => {
          this.currentUserProfile.next(res);
        })
      );
    } else {
      return this.http.put(`${this.endPointUserProfile}/user`, profile).pipe(
        switchMap((res) => {
          return this.getProfile();
        }),
        tap((res) => {
          this.currentUserProfile.next(res);
        })
      );
    }
  }

  deleteAccount(app): Observable<any> {
    return this.http.delete(`${this.endPointUserProfile}/delete-user/${app}`);
  }

  updateMobile(phone): Observable<any> {
    if (this.local) {
      return this.http
        .put(`${this.endPointUserProfile}/update-mobile`, phone)
        .pipe(
          switchMap((res) => {
            return this.getProfile();
          }),
          tap((res) => {
            this.currentUserProfile.next(res);
            // this.userProfile = res;
          })
        );
    } else {
      return this.http
        .put(`${this.endPointUserProfile}/update-mobile`, phone)
        .pipe(
          switchMap((res) => {
            return this.getProfile();
          }),
          tap((res) => {
            console.log("this current profile ---", res);
            this.currentUserProfile.next(res);
            // this.userProfile = res;
          })
        );
    }
  }

  uploadAvatar(formData): Observable<any> {
    return this.http.post(`${this.endPointFiles}/avatar`, formData).pipe(
      map((res: any) => {
        res.filename += "?" + Date.now();
        return res;
      })
    );
  }

  getFilesDownload(document, saveTitle) {
    if (this.local) {
      return this.http.get(`${this.endPointFiles}/123`).pipe(
        map((res: any) => {
          return this.b64toBlob(res.blob, "image/png");
        })
      );
    } else {
      return this.http.get(`${this.endPointFiles}/download/${document}`, {
        responseType: "blob",
      });
    }
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
