import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  Observable,
  BehaviorSubject,
  Subject,
  EMPTY,
  from,
  pipe,
  throwError,
  takeUntil,
  tap,
  delay,
  switchMap,
  map,
  catchError,
} from "rxjs";
import * as Sentry from "@sentry/browser";
import { Intercom } from "@supy-io/ngx-intercom";
import jwtDecode from "jwt-decode";
import { environment } from "../../../../environments/environment";
import { ProfileService } from "../../profile/profile.service";
import { NavController } from "@ionic/angular";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ConfigurationService } from "src/app/shared/services/configuration.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  endPoint;
  token: string;
  tokenData = new BehaviorSubject<any>(null);
  local = environment.e2e;

  stopSignal$ = new Subject();

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private intercom: Intercom,
    private navController: NavController,
    public fireAuth: AngularFireAuth,
    private configService: ConfigurationService
  ) {
    this.endPoint = this.configService.apiUrl+ environment.apiUrlAuth;
  }

  signIn(userData): Observable<any> {
    this.stopSignal$ = new Subject();
    if (this.local) {
      return this.http.get(`api/login/${encodeURI(userData.password)}`).pipe(
        tap({
          next: (token: any) => {
            console.log("token --- ", token);
            this.postLoginValidationChecks(token);
          },
          error: (err) => throwError(err),
        }),
        map((token) => {
          token.token = token.id;
          return token;
        }),
        takeUntil(this.stopSignal$),
        this.loginPipe()
      );
    } else {
      return this.http.post(`${this.endPoint}/login/taskezy`, userData).pipe(
        tap({
          next: (token: any) => {
            console.log(token);
            this.postLoginValidationChecks(token);
          },
          error: (err) => throwError(err),
        }),
        takeUntil(this.stopSignal$),
        this.loginPipe()
      );
    }
  }

  changePassword(data) {
    const tokenValue = this.tokenData.getValue();
    return this.http.post(`${this.endPoint}/change-password/taskezy`, {
      currentPassword: data.oldPass,
      loginId: tokenValue?.email,
      password: data.newPass,
    });
  }

  refreshLoginToken(body): Observable<any> {
    this.stopSignal$ = new Subject();
    if (this.local) {
      return this.http.get(`api/login/${encodeURI(body.refreshToken)}`).pipe(
        delay(300),
        tap({
          next: (token: any) => {
            console.log("login set token --- ");
            this.postLoginValidationChecks(token);
          },
          error: (err) => throwError(err),
        }),
        map((token) => {
          token.token = token.id;
          return token;
        }),
        takeUntil(this.stopSignal$),
        this.loginPipe()
      );
    } else {
      return this.http.post(`${this.endPoint}/refresh`, body).pipe(
        tap({
          next: (token: any) => {
            this.postLoginValidationChecks(token);
          },
          error: (err) => throwError(err),
        }),
        takeUntil(this.stopSignal$),
        this.loginPipe()
      );
    }
  }

  signOut() {
    this.removeUserInfor();
    this.removeToken();
    Sentry.setUser(null);
    this.profileService.initServiceValue();
    this.navController.navigateRoot(["/auth/login"]);
    this.intercom.shutdown();
    this.tokenData.next(null);
  }

  getToken(): string {
    this.token = localStorage.getItem("token");
    return this.token;
  }

  getTokenExpirationDate(token: string): Date {
    try {
      const decoded: any = jwtDecode(token, { header: true });
      if (!decoded.exp) {
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);
      return date;
    } catch (e) {
      return null;
    }
  }

  getTokenData(token) {
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    this.tokenData.next(tokenData);
    return tokenData;
  }

  loginPipe = () =>
    pipe(
      tap((res: any) => {
        this.profileService.getUserProfileAndAccounts().subscribe(() => {
          this.navController.navigateForward(["/dashboard"]);
          
        });
        console.log("login pipe:", res);
      }),
      catchError((err: any) => {
        this.signOut();
        throw err;
      })
    );

  private removeToken(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }

  private removeUserInfor(): void {
    localStorage.removeItem("user_id");
    localStorage.removeItem("currentAccount");
    localStorage.removeItem("email");
    localStorage.removeItem("dashboard_status");
    // localStorage.removeItem('ftoken');
  }

  isAuthenticated(): Observable<any> {
    if (this.local) {
      return this.tokenData.pipe(
        map((res) => ({
          isAuth: true,
          emailVerified: res?.email_verified,
          mobileVerified: res?.mobile_verified,
          profileVerified: res?.profile_verified,
        }))
      );
    } else {
      return this.tokenData.pipe(
        map((res) => ({
          isAuth: !!this.getToken(),
          emailVerified: res?.email_verified,
          mobileVerified: res?.mobile_verified,
          profileVerified: res?.profile_verified,
        }))
      );
    }
  }

  postLoginValidationChecks(token) {
    // console.log(token);
    // const tokenData: any = this.getTokenData(token.token);
    this.token = token.token;
    localStorage.setItem("user_id", token.user.id);
    localStorage.setItem("email", token.user.email);
    localStorage.setItem("refreshToken", token.refreshToken);
    localStorage.setItem("token", token.token);
    this.getTokenData(this.token);
    const tokenData = this.tokenData.getValue();
    console.log(tokenData);
    // if (token?.user) {
    //   localStorage.setItem("pending-user", JSON.stringify(token.user));
    // }
    // localStorage.setItem("refreshToken", token.refreshToken);
    // localStorage.setItem("token", token.token);

    if (token.statusCode === 212) {
      console.log("here");
      this.navController.navigateRoot(["/auth/verify-email"]);
      this.stopSignal$.next(true);
    } else {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: token.user.id,
          email: token.user.email,
        });
      });

      this.intercom.boot({
        app_id: environment.intercomAPIKey,
        // widget: {
        //   activator: "#intercom",
        // },
        email: token.user.email,
        user_id: token.user.id,
        hide_default_launcher: true,
      });

      if(!tokenData) {
        this.stopSignal$.next(true);
      } else if (!tokenData.email_verified) {
        this.navController.navigateRoot(["/auth/verify-email"]);
        this.stopSignal$.next(true);
      } else if (!tokenData.mobile_verified) {
        this.navController.navigateRoot(["/auth/verify-mobile"]);
        this.stopSignal$.next(true);
      } else if (tokenData.profile_verified < 2) {
        this.profileService.getUserProfileAndAccounts().subscribe();
        this.navController.navigateRoot(["/auth/identification"]);
        this.stopSignal$.next(true);
      } else {
        // Fully Verified
      }
    }
    return tokenData;
  }

  loginToFirebase() {
    if (this.token) {
      return this.http
        .post(`${this.endPoint}/jwt/firebase`, { token: this.token })
        .pipe(
          switchMap((fbToken: any) => {
            console.log(fbToken);
            if (fbToken.statusCode == 200) {
              return from(this.fireAuth.signInWithCustomToken(fbToken.token));
            } else {
              return EMPTY;
            }
          }),
          tap(
            (success) => {
              console.log(success);
            },
            (error) => {
              console.log(error);
            }
          )
        );
    } else {
      return EMPTY;
    }
  }
}
