// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-account-screen-container {
  padding: 20px;
}
.auth-account-screen-container .title-main {
  text-align: center;
  margin-top: 34px;
  color: var(--ion-color-primary);
  font-size: 24px;
}
.auth-account-screen-container .btn-group-container {
  margin-top: 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImF1dGgtYWNjb3VudC5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0FBQ0Y7QUFDRTtFQUNFLGtCQUFBO0VBQ0EsZ0JBQUE7RUFDQSwrQkFBQTtFQUNBLGVBQUE7QUFDSjtBQUVFO0VBQ0UsZ0JBQUE7QUFBSiIsImZpbGUiOiJhdXRoLWFjY291bnQucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmF1dGgtYWNjb3VudC1zY3JlZW4tY29udGFpbmVyIHtcbiAgcGFkZGluZzogMjBweDtcblxuICAudGl0bGUtbWFpbiB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIG1hcmdpbi10b3A6IDM0cHg7XG4gICAgY29sb3I6IHZhcigtLWlvbi1jb2xvci1wcmltYXJ5KTtcbiAgICBmb250LXNpemU6IDI0cHg7XG4gIH1cblxuICAuYnRuLWdyb3VwLWNvbnRhaW5lciB7XG4gICAgbWFyZ2luLXRvcDogMzBweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/main/authentication/auth-account/auth-account.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,kBAAA;EACA,gBAAA;EACA,+BAAA;EACA,eAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AACA,gqBAAgqB","sourcesContent":[".auth-account-screen-container {\n  padding: 20px;\n\n  .title-main {\n    text-align: center;\n    margin-top: 34px;\n    color: var(--ion-color-primary);\n    font-size: 24px;\n  }\n\n  .btn-group-container {\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
