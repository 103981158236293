import { Injectable } from "@angular/core";
import { PresentersService } from "./presenters.service";
// import * as linkify from 'linkifyjs';
// import linkifyHtml from 'linkify-html';
import { Platform } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { VERSION } from "src/environments/version";
import { BehaviorSubject } from "rxjs";
import { parse } from "node-html-parser";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  termsInnerHtml = new BehaviorSubject("");
  privacyInnerHtml = new BehaviorSubject("");
  versionOfApp = "";

  constructor(
    public platform: Platform,
    public presenters: PresentersService,
  ) {
    this.getPrivacy();
    this.getTerms();
  }

  getVersionOfApp() {
    const version = `${VERSION.version}`;
    this.versionOfApp = version;
  }

  getTerms() {
    (async () => {
      const response = await fetch("https://www.taskezy.com/terms/");
      if (response.status == 200) {
        const text = await response.text();
        const parsedText = parse(text);
        // console.log(parsedText);
        this.termsInnerHtml.next(
          parsedText.querySelector(
            "article > div > div > div > div > div > div"
          ).innerHTML
        );
      }
    })();
  }

  getPrivacy() {
    (async () => {
      const response = await fetch("https://www.taskezy.com/privacy/");
      if (response.status === 200) {
        const text = await response.text();
        const parsedText = parse(text);
        // console.log(parsedText);
        this.privacyInnerHtml.next(
          parsedText.querySelector(
            "article > div > div > div > div > div > div"
          ).innerHTML
        );
      }
    })();
  }

  b64toBlob(dataURI: any) {
    const dataURIObj = dataURI.split(",");
    const dataURIData = dataURIObj.length > 1? dataURIObj[1]: dataURIObj[0]
    const byteString = atob(dataURIData);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
  }

  randomString(len) {
    const r = Math.random()
      .toString(36)
      .substring(len + 1);
    console.log("random", r);
    return r;
  }

  forceReload() {
    // eslint-disable-next-line import/no-deprecated
    window.location.reload();
  }

  webShare(title, text, url) {
    if (window.navigator && window.navigator["share"]) {
      window.navigator["share"]({
        title,
        text,
        url,
      })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  }

  errorAndroidPermission(errMsg = "") {
    this.presenters.presentAlert({
      header: "No Permission",
      message: `You will need to enable permission for the TaskEzy app. ${errMsg}`,
      buttons: ["Ok"],
    });
  }

  getEnv() {
    if (environment.production) {
      return "prod";
    } else if (environment.stage) {
      return "stage";
    } else if (environment.test) {
      return "test";
    } else if (environment.dev) {
      return "dev";
    } else {
      return "e2e";
    }
  }
}
