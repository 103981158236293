export class ResourcesFakeDb {
  public static resources = [
    {
      id: "bdfg42nd563",
      uuid: "bdfg42nd563",
      detail: {
        primaryindustry: "Security",
        abn: "909090888888",
        acn: "2378273982798789",
        name: "Trevino Marvel",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "92352424",
        email: "trevino@gmail.com",
      },
      documents: [],
      accreditation: [],
      members: [],
      venues: [],
    },
    {
      id: "qg63ojdfj43w",
      uuid: "qg63ojdfj43w",
      detail: {
        primaryindustry: "Security",
        abn: "345678987",
        acn: "3454566663322",
        name: "Tyson Cherry",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "0864321",
        email: "tyson@gmail.com",
      },
      documents: [],
      accreditation: [],
      members: [],
      venues: [],
    },
  ];
}
