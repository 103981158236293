import { InMemoryDbService } from "angular-in-memory-web-api";

import { TasksFakeDb } from "./tasks";
import { IncidentsFakeDb } from "./incidents";
import { AccountFakeDb } from "./account";
import { AccreditationFakeDb } from "./accreditation";
import { Injectable } from "@angular/core";
import { LoginUserFakeDb } from "./login-user";
import { LicenseFakeDb } from "./license";
// import {RegisterFakeDb} from './register';


@Injectable()
export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // login
      login: LoginUserFakeDb.users,
      // Tasks
      tasks: TasksFakeDb.tasks,

      // userAccreditation
      userAccreditation: AccreditationFakeDb.userAccreditations,

      // Incidents
      incidents: IncidentsFakeDb.incidents,
      labelsForContent2: IncidentsFakeDb.labelsForContent2,
      labelsForContent3: IncidentsFakeDb.labelsForContent3,
      labelsForContent5: IncidentsFakeDb.labelsForContent5,
      labelsForContent5CCTV: IncidentsFakeDb.labelsForContent5CCTV,

      // Roster Tasks
      shifts: TasksFakeDb.shifts,

      // Profiles
      accounts: AccountFakeDb.accounts,
      accreditation: AccreditationFakeDb.accreditations,
      // 'security/nsw/:id': AccreditationFakeDb.errorMsg,
      "compliance-security-nsw": AccreditationFakeDb.securityLicenses,
      incidentformschema: IncidentsFakeDb.formSchema,
      // 'register-data': RegisterFakeDb.dataRegisterPage,
    };
  }
}
