import { ClientsFakeDb } from "./clients";
import { SuppliersFakeDb } from "./suppliers";
import { ResourcesFakeDb } from "./resources";

export class AccountFakeDb {
  public static accounts = [
    {
      id: "1234-1234-1324-1324",
      uuid: "1234-1234-1324-1324",
      detail: {
        primaryindustry: "Security",
        abn: "909090888888",
        acn: "2378273982798789",
        name: "Robert's Super Security Firm Pty Ltd",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "0123456789",
        email: "email@email.com",
        avatar:
          "http://res.cloudinary.com/lwve0xa7a/image/upload/v1590580527/gofer/angular_oklmam.png",
      },
      documents: [],
      accreditation: [],
      primaryadmin: "",
      clients: ClientsFakeDb.clients,
      suppliers: SuppliersFakeDb.suppliers,
      resources: ResourcesFakeDb.resources,
      members: [
        {
          user_id: "11111111",
          email: "wang-test@test.com",
          role_name: "Administrator",
          role: 3,
        },
        {
          user_id: "55555555",
          email: "wang-test5@test.com",
          role_name: "Manager",
          role: 2,
        },
      ],
      venues: [
        {
          accreditation_requirements: [
            {
              description: "This is the licence description",
              name: "NSW Security Licence",
              source: "https://www.example.com/licence.pdf",
              template_uuid: "123456789",
              timing_requirement: "Before Shift Start",
              type: "Security",
            },
          ],
          address: {
            geo_location: {
              coordinates: [0],
              type: "string",
            },
            "google-call": "string",
            "google-place-id": "string",
            street_location: "23 Norton Plaza, Leichardt, NSW, 2040",
          },
          comment: "string",
          contract_review_date: "2023-01-01",
          contract_start_date: "2019-01-01",
          contract_term: "2 Years",
          documents: [
            {
              date_added: "string",
              date_modified: "string",
              description: "string",
              name: "string",
              private: true,
              tag: "string",
              uuid: "string",
            },
          ],
          executing_parties: "John Smith, CEO Peter Holiday, Manager",
          licence_name: "Licence Name",
          licence_number: "123456789",
          logo: "https://www.example.com/logo.png",
          name: "Venue Name 2",
          roles: [
            {
              credentials: [
                {
                  data: "string",
                  roles: [0],
                  tags: ['["tag1"', '"tag2"]'],
                  template: "1234-1234-1234",
                  type: "Induction",
                  uuid: "1234-1234-1234",
                },
              ],
              description: "Guard description",
              item_code: "#123-321",
              name: "Guard",
              rates: [
                {
                  item_code: "#123 456",
                  name: "Standard",
                  value: 10,
                },
              ],
              uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            },
          ],
          stations: [
            {
              name: "string",
            },
          ],
          type: "Private",
          uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        },
      ],
    },
    {
      id: "tgetgwe53634grtgw",
      uuid: "tgetgwe53634grtgw",
      detail: {
        primaryindustry: "Security",
        abn: "909090888888",
        acn: "2378273982798789",
        name: "Test 1",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "0123456789",
        email: "email@email.com",
      },
      documents: [],
      accreditation: [],
      primaryadmin: "",
      clients: [],
      suppliers: [],
      resources: [],
      members: [
        {
          user_id: "55555555",
          email: "wang-test5@test.com",
          role_name: "Manager",
          role: 2,
        },
      ],
      venues: [],
    },
    {
      id: "8454gresw4353gr7df",
      uuid: "8454gresw4353gr7df",
      detail: {
        primaryindustry: "Security",
        abn: "909090888888",
        acn: "2378273982798789",
        name: "Test 2",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "0123456789",
        email: "email@email.com",
      },
      documents: [],
      accreditation: [],
      primaryadmin: "",
      clients: ClientsFakeDb.clients,
      suppliers: SuppliersFakeDb.suppliers,
      resources: ResourcesFakeDb.resources,
      members: [
        {
          user_id: "55555555",
          email: "wang-test5@test.com",
          role_name: "Manager",
          role: 2,
        },
      ],
      venues: [
        {
          accreditation_requirements: [
            {
              description: "This is the licence description",
              name: "NSW Security Licence",
              source: "https://www.example.com/licence.pdf",
              template_uuid: "123456789",
              timing_requirement: "Before Shift Start",
              type: "Security",
            },
          ],
          address: {
            geo_location: {
              coordinates: [0],
              type: "string",
            },
            "google-call": "string",
            "google-place-id": "string",
            street_location: "23 Norton Plaza, Leichardt, NSW, 2040",
          },
          comment: "string",
          contract_review_date: "2023-01-01",
          contract_start_date: "2019-01-01",
          contract_term: "2 Years",
          documents: [
            {
              date_added: "string",
              date_modified: "string",
              description: "string",
              name: "string",
              private: true,
              tag: "string",
              uuid: "string",
            },
          ],
          executing_parties: "John Smith, CEO Peter Holiday, Manager",
          licence_name: "Licence Name",
          licence_number: "123456789",
          logo: "https://www.example.com/logo.png",
          name: "Venue Name 1",
          roles: [
            {
              credentials: [
                {
                  data: "string",
                  roles: [0],
                  tags: ['["tag1"', '"tag2"]'],
                  template: "1234-1234-1234",
                  type: "Induction",
                  uuid: "1234-1234-1234",
                },
              ],
              description: "Guard description",
              item_code: "#123-321",
              name: "Guard",
              rates: [
                {
                  item_code: "#123 456",
                  name: "Standard",
                  value: 10,
                },
              ],
              uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            },
          ],
          stations: [
            {
              name: "string",
            },
          ],
          type: "Private",
          uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        },
      ],
    },
  ];
}
