import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { PreferencePage } from "./preference.page";
import { CodeInputModule } from "angular-code-input";

const routes: Routes = [
  {
    path: "preference",
    component: PreferencePage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CodeInputModule,
    RouterModule.forChild(routes),
  ],
  declarations: [PreferencePage],
})
export class PreferencePageModule {}
