import { Injectable } from "@angular/core";

import { switchMap, take } from "rxjs/operators";

import { LoginService } from "../../main/authentication/login/login.service";
import { ProfileService } from "../../main/profile/profile.service";
import { EMPTY } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RefreshService {
  refreshToken;

  constructor(
    private loginService: LoginService,
    private profileService: ProfileService,
    private router: Router
  ) {}

  loginWithRefresh() {
    if (localStorage.getItem("refreshToken")) {
      this.refreshWithRefreshToken().subscribe();
    } else {
      return EMPTY;
    }
  }

  refreshWithRefreshToken() {
    this.refreshToken = localStorage.getItem("refreshToken");
    return this.loginService.refreshLoginToken({
      refreshToken: this.refreshToken,
    });
  }
}
