export class ClientsFakeDb {
  public static clients = [
    {
      id: "345456l",
      uuid: "345456l",
      detail: {
        primaryindustry: "Security",
        abn: "564647574",
        acn: "2378273982798789",
        name: "Selvador Kris",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "1234098",
        email: "selvador@gmail.com",
      },
      documents: [],
      accreditation: [],
      members: [],
      venues: [],
    },
    {
      id: "98746563f",
      uuid: "98746563f",
      detail: {
        primaryindustry: "Security",
        abn: "345678987",
        acn: "3454566663322",
        name: "Great Man",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phone_number: "0864321",
        email: "man@gmail.com",
      },
      documents: [],
      accreditation: [],
      members: [],
      venues: [],
    },
  ];
}
