export const environment = {
  env: "test-resource",
  keyGoogleMap: "AIzaSyBQA7mw-dqtx75RiC7kvSF_NjTavatq7tA",
  production: false,
  stage: false,
  test: true,
  dev: false,
  e2e: false,
  tenant: "taskezy",
  apiUrlAuth: "/auth/1.1",
  apiUrlAccount: "/profile/1.2",
  apiUrlCompliance: "/compliance/1.1",
  apiUrlRoster: "/roster/1.1",
  apiUrlFormbuilder: "/formbuilder/1.0",
  apiUrlFiles: "https://testdoc.gravityfusion.com.au",
  sentryDsn:
    "https://55fb2e6ad99c4d4599edaf5719f029c9@o26287.ingest.sentry.io/5191548",
  intercomAPIKey: "cmkwfq1c",
  apiGoogleMap: "https://maps.googleapis.com/maps/api",
  firebase: {
    apiKey: "AIzaSyCnmOyvGlCtkXElkQXC3h_3iRSR9T6sG50",
    authDomain: "totallyezy.firebaseapp.com",
    projectId: "totallyezy",
    storageBucket: "tboss-ident",
    messagingSenderId: "228822179711",
  },
};
