import { Injectable } from '@angular/core';
import { PopoverController, LoadingController, ModalController, AlertController,
   ActionSheetController, ToastController } from '@ionic/angular';
import { PopoverOptions, ModalOptions, LoadingOptions, AlertOptions, ActionSheetOptions, ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})

export class PresentersService {

  popover: any;
  loader: any;
  modal: any;
  alert: any;
  actionSheet: any;
  toast: any;

  constructor(
    public loadingCtrl: LoadingController,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public toastCtrl: ToastController
  ) { }

  async presentPopover(popOpts: PopoverOptions) {
    this.popover = await this.popoverCtrl.create(popOpts);
    await this.popover.present();
    return this.popover.onDidDismiss();
  }

  async presentModal(modOpts: ModalOptions) {
    this.modal = await this.modalCtrl.create(modOpts);
    await this.modal.present();
    return this.modal.onDidDismiss();
  }

  async presentLoader(loadOpts: LoadingOptions) {
    this.loader = await this.loadingCtrl.create(loadOpts);
    await this.loader.present();
    return this.loader.onDidDismiss();
  }

  async presentAlert(alertOpts: AlertOptions) {
    this.alert = await this.alertCtrl.create(alertOpts);
    await this.alert.present();
    return this.alert.onDidDismiss();
  }

  async presentActionsheet(actionOpts: ActionSheetOptions) {
    this.actionSheet = await this.actionSheetCtrl.create(actionOpts);
    await this.actionSheet.present();
    return this.actionSheet.onDidDismiss();
  }

  async presentToast(toastOpts: ToastOptions) {
    this.toast = await this.toastCtrl.create(toastOpts);
    await this.toast.present();
    return this.toast.onDidDismiss();
  }

  dismissPopover(obj?) {
    if (this.popover) {
      if (obj) {
        this.popover.dismiss(obj);
      } else {
        this.popover.dismiss();
      }
    }
  }

  dismissModal(obj?) {
    if (this.modal) {
      if (obj) {
        this.modal.dismiss(obj);
      } else {
        this.modal.dismiss();
      }
    }
  }

  dismissLoader() {
    if (this.loader) {
      this.loader.dismiss();
    }
  }

  dismissAlert(obj?) {
    if (this.alert) {
      if (obj) {
        this.alert.dismiss(obj);
      } else {
        this.alert.dismiss();
      }
    }
  }

  dismissActionSheet(obj?) {
    if (this.actionSheet) {
      if (obj) {
        this.actionSheet.dismiss(obj);
      } else {
        this.actionSheet.dismiss();
      }
    }
  }

  dismissToast(obj?) {
    if (this.toast) {
      if (obj) {
        this.toast.dismiss(obj);
      } else {
        this.toast.dismiss();
      }
    }
  }
}
