export class LoginUserFakeDb {
  public static users = [
    {
      id: 11111111,
      user_id: "11111111",
      loginId: "wang-test@test.com",
      user: {
        id: 11111111,
        email: "wang-test@test.com",
        data: { mobileVerified: true, profileVerified: 2 },
        firstName: "wang",
        lastName: "1111",
        fullName: "wang 1111",
        mobilePhone: "+61412341234",
        usernameStatus: "ACTIVE",
        verified: true,
        active: true,
        emailVerified: true,
      },
      password: "11111111",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0QHRlc3QuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFwcGxpY2F0aW9uSWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJyb2xlcyI6W10sInByb2ZpbGVfdmVyaWZpZWQiOjIsIm1vYmlsZSI6Iis2MTQxMjM0MTIzNCIsIm1vYmlsZV92ZXJpZmllZCI6dHJ1ZX0.F2TIU3HXlEZ800h4Qap3R6crdMO3RM63X_I2Z7cUQxY",
      refreshToken: "11111111",
      statusCode: 200,
    },
    {
      id: 22222222,
      user_id: "22222222",
      loginId: "wang-test2@test.com",
      user: {
        id: 22222222,
        email: "wang-test2@test.com",
        data: { mobileVerified: true, profileVerified: 0 },
        firstName: "wang",
        lastName: "2222",
        fullName: "wang 2222",
        mobilePhone: "+61412341234",
        usernameStatus: "ACTIVE",
        verified: true,
        active: true,
        emailVerified: true,
      },
      password: "22222222",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0MkB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjowLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOnRydWV9.ld2P841901Q1qpvf8KdrvXLe_mwT8aNAs_-vrZ0Xe8Q",
      refreshToken: "22222222",
      statusCode: 200,
    },
    {
      id: 33333333,
      loginId: "wang-test3@test.com",
      user_id: "33333333",
      user: {
        id: 33333333,
        email: "wang-test3@test.com",
        data: { mobileVerified: false, profileVerified: 0 },
        firstName: "wang",
        lastName: "3333",
        fullName: "wang 3333",
        mobilePhone: "+61412341234",
        usernameStatus: "ACTIVE",
        verified: true,
        active: true,
        emailVerified: true,
      },
      password: "33333333",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0M0B0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjowLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOmZhbHNlfQ.YkhOz2muI7U4inBmVfOAYJlrJghTp-tgDelJohm3GW4",
      refreshToken: "33333333",
      statusCode: 200,
    },
    {
      id: 44444444,
      user_id: "44444444",
      loginId: "wang-test4@test.com",
      user: {
        id: 44444444,
        email: "wang-test4@test.com",
        data: { mobileVerified: false, profileVerified: 0 },
        firstName: "wang",
        lastName: "4444",
        fullname: "wang 4444",
        mobilePhone: "+61412341234",
        usernameStatus: "ACTIVE",
        verified: true,
        active: true,
        emailVerified: false,
      },
      password: "44444444",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0NEB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiYXBwbGljYXRpb25JZCI6IjJiNDBlYjQ3LWIzZjItNGFlNS1iNTRlLTNiOGZmMTE0Y2Y0YSIsInJvbGVzIjpbXSwicHJvZmlsZV92ZXJpZmllZCI6MCwibW9iaWxlIjoiKzYxNDEyMzQxMjM0IiwibW9iaWxlX3ZlcmlmaWVkIjpmYWxzZX0.a1fOuqwpDeXQRBJayFfL-AXQp68gVrxdvRVkYqaXX8U",
      refreshToken: "44444444",
      statusCode: 212,
    },
    {
      id: 55555555,
      user_id: "55555555",
      loginId: "wang-test5@test.com",
      user: {
        id: 55555555,
        email: "wang-test5@test.com",
        data: { mobileVerified: true, profileVerified: 2 },
        firstName: "wang",
        lastName: "1111",
        fullName: "wang 1111",
        mobilePhone: "+61412341234",
        usernameStatus: "ACTIVE",
        verified: true,
        active: true,
        emailVerified: true,
      },
      password: "55555555",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0NUB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjoyLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOnRydWV9.rUUmc3dlTj-C34YJ-F47cRgnY72zy4yKa0zOOb9fSZ4",
      refreshToken: "55555555",
      statusCode: 200,
    },
  ];
  public static refresh = [
    {
      id: 11111111,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0QHRlc3QuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFwcGxpY2F0aW9uSWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJyb2xlcyI6W10sInByb2ZpbGVfdmVyaWZpZWQiOjIsIm1vYmlsZSI6Iis2MTQxMjM0MTIzNCIsIm1vYmlsZV92ZXJpZmllZCI6dHJ1ZX0.F2TIU3HXlEZ800h4Qap3R6crdMO3RM63X_I2Z7cUQxY",
    },
    {
      id: 22222222,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0MkB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjowLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOnRydWV9.ld2P841901Q1qpvf8KdrvXLe_mwT8aNAs_-vrZ0Xe8Q",
    },
    {
      id: 33333333,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0M0B0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjowLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOmZhbHNlfQ.YkhOz2muI7U4inBmVfOAYJlrJghTp-tgDelJohm3GW4",
    },
    {
      id: 44444444,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0NEB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiYXBwbGljYXRpb25JZCI6IjJiNDBlYjQ3LWIzZjItNGFlNS1iNTRlLTNiOGZmMTE0Y2Y0YSIsInJvbGVzIjpbXSwicHJvZmlsZV92ZXJpZmllZCI6MCwibW9iaWxlIjoiKzYxNDEyMzQxMjM0IiwibW9iaWxlX3ZlcmlmaWVkIjpmYWxzZX0.a1fOuqwpDeXQRBJayFfL-AXQp68gVrxdvRVkYqaXX8U",
    },
    {
      id: 55555555,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjdkNjNiYTg2MzQifQ.eyJhdWQiOiIyYjQwZWI0Ny1iM2YyLTRhZTUtYjU0ZS0zYjhmZjExNGNmNGEiLCJleHAiOjE2NDY4ODY1OTYsImlhdCI6MTY0Njg4Mjk5NiwiaXNzIjoidGFza2V6eS5jb20iLCJzdWIiOiJjNWJkNTM4Mi0wMmJmLTQ3MjMtOWQxYy01ZGZhMDc0ZmUwMGEiLCJqdGkiOiIzYTEyMTYxYy00OTc2LTRiNTAtOTFlNC1lMGIyMDM0MTdmNzEiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoid2FuZy10ZXN0NUB0ZXN0LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhcHBsaWNhdGlvbklkIjoiMmI0MGViNDctYjNmMi00YWU1LWI1NGUtM2I4ZmYxMTRjZjRhIiwicm9sZXMiOltdLCJwcm9maWxlX3ZlcmlmaWVkIjoyLCJtb2JpbGUiOiIrNjE0MTIzNDEyMzQiLCJtb2JpbGVfdmVyaWZpZWQiOnRydWV9.rUUmc3dlTj-C34YJ-F47cRgnY72zy4yKa0zOOb9fSZ4",
    },
  ];
}
